import React from "react";

import Router from 'components/Router';

import 'assets/css/style.css';

function App() {
  return (
    <Router />
  );
}

export default App;
